<template>
  <b-container class="page-body mx-lg">
    <reportHeader :currentDisplayDate="showingDate" :displayType="displayType" :i18n="translations.components"
      @addEditPrayerRequest="addEditPrayerRequest()" @filterBy="filter($event)"></reportHeader>
    <br />
    <br />
    <div class="calendar-area">
      <b-row class="text-center text-color-black">
        <b-col cols="3">
          <form @submit.prevent="setCurrentCamp" class="filters justify-content-start justify-content-sm-end">
            <b-form-select v-model="option" :options="options" :plain="true" @change="loadPrayerRequest()"
              class="church-select mr-0 mr-sm-2 mb-3 mb-sm-0">
              <template slot="first">
                <option :value="null" disabled></option>
              </template>
            </b-form-select>
          </form>
        </b-col>
        <b-col cols="2"></b-col>
        <b-col cols="2">
          <h5>{{ currentMonth }}</h5>
        </b-col>
        <b-col cols="2"></b-col>
        <b-col cols="3">
          <img class="csv" src />
        </b-col>
      </b-row>
      <b-row class="calendar-row pt-4">
        <b-col>
          <full-calendar v-if='translations.common' :config='config' :events.sync='events'
            @event-selected='selectedEvent($event)' />
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import dateData from '@/json/date.json'
import { FullCalendar } from 'vue-full-calendar'
import 'fullcalendar/dist/fullcalendar.css'
import ReportHeader from '@/components/ReportHeader.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'prayer-center-monthly',
  mixins: [translationMixin],
  data() {
    return {
      currentMonth: '',
      showingDate: 0,
      displayType: 'monthly',
      currentFilter: 'all',
      areaKeyMap: [],
      prayers: [],
      events: [],
      org_key: null,
      config: {
        defaultView: 'month',
        displayEventTime: false,
        display: 'block',
        header: false,
        eventBackgroundColor: '#003946',
        eventTextColor: '#F0F0F0',
        locale: 'en',
        weekday: 'short',
        eventRender: (event, element) => { },
      },
      option: null,
      translations: {},
    }
  },
  methods: {
    ...mapActions({
      getCampPrayerListing: 'prayerCenter/getCampPrayerListing',
      getStatePrayerListing: 'prayerCenter/getStatePrayerListing',
      getInternationalPrayerListing: 'prayerCenter/getInternationalPrayerListing',
      getMonthPrayerRequest: 'prayerCenter/getMonthPrayerRequest',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedPrayerRequest: 'prayerCenter/setSelectedPrayerRequest',
    }),
    async loadPrayerRequest() {
      this.setLoadingStatus(true)
      let keyMap = this.areaKeyMap.find((map) => map.value === this.option)
      let ocdata = {
        orgKey: keyMap.area_key,
        cprKey: this.option,
      }
      this.org_key = keyMap.area_key
      await this.getMonthPrayerRequest(ocdata)
      await this.extrapolateEvents()
      this.setLoadingStatus(false)
    },
    async extrapolateEvents() {
      if (!!this.monthPrayerRequest && this.monthPrayerRequest.length > 0) {
        this.events = await this.monthPrayerRequest.map((dta) => {
          let startDate = new Date(this.showingDate.getFullYear(), this.showingDate.getMonth(), dta.day, 1, 2, 3)
          return {
            title: dta.title,
            start: startDate,
            data: dta,
          }
        })
      }
    },
    addEditPrayerRequest() {
      this.$router.push({ name: 'prayer-add-edit-request' })
    },
    async selectedEvent(event) {
      await this.setSelectedPrayerRequest({ details: event.data, orgKey: this.org_key, type: 'edit' })
      this.$router.push({ name: 'prayer-details' })
    },
    filter($event) {
      this.currentFilter = $event
    },
    setAreaKeyMap() {
      let orgKeyMap = !!this.campPrayerListing.calendars
        ? this.campPrayerListing.calendars
          .filter((data) => data.active === true && data.type !== 'Special')
          .map((data) => {
            let areaKeyMap = {
              area_key: this.campPrayerListing.org,
              value: data.key,
            }
            return areaKeyMap
          })
        : []

      let stateKeyMap = !!this.statePrayerListing.calendars
        ? this.statePrayerListing.calendars
          .filter((data) => data.active === true && data.type !== 'Special')
          .map((data) => {
            let areaKeyMap = {
              area_key: this.statePrayerListing.org,
              value: data.key,
            }
            return areaKeyMap
          })
        : []

      let internationalKeyMap = !!this.internationalPrayerListing.calendars
        ? this.internationalPrayerListing.calendars
          .filter((data) => data.active === true && data.type !== 'Special')
          .map((data) => {
            let areaKeyMap = {
              area_key: this.internationalPrayerListing.org,
              value: data.key,
            }
            return areaKeyMap
          })
        : []
      this.areaKeyMap = this.areaKeyMap.concat(orgKeyMap, stateKeyMap, internationalKeyMap)
    },
    getCampPrayerListingData() {
      return !!this.campPrayerListing.calendars
        ? this.campPrayerListing.calendars
          .filter((data) => data.active === true && data.type !== 'Special')
          .map((data) => {
            let formattedData = {
              value: data.key,
              text: data.name,
            }
            return formattedData
          })
        : []
    },
    getStatePrayerListingData() {
      return !!this.statePrayerListing.calendars
        ? this.statePrayerListing.calendars
          .filter((data) => data.active === true && data.type !== 'Special')
          .map((data) => {
            let formattedData = {
              value: data.key,
              text: data.name,
            }
            return formattedData
          })
        : []
    },
    getInternationalPrayerListingData() {
      return !!this.internationalPrayerListing.calendars
        ? this.internationalPrayerListing.calendars
          .filter((data) => data.active === true && data.type !== 'Special')
          .map((data) => {
            let formattedData = {
              value: data.key,
              text: data.name,
            }
            return formattedData
          })
        : []
    },
    async pageLoad() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          this.getViewTranslations(),
          this.getComponentTranslations('camp-select', 'report-header', 'security-banner'),
          this.getComponentTranslations('common.months'),
          await this.getCampPrayerListing(),
          await this.getStatePrayerListing(),
          await this.getInternationalPrayerListing(),
        ]).then(results => {
          this.config.locale = this.prefCulture
          this.setAreaKeyMap()
          this.stripReadableText(results[2])
          const translatedText = {
            ...results[1],
            common: { ...this.translations.common }
          }
          this.$set(this.translations, 'components', translatedText)
          this.setCurrentMonth()
        })
      } catch (e) {
        console.error('Error in PrayerCenterMonthly.vue, pageLoad(), ', e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    setCurrentMonth() {
      this.currentMonth = this.returnFormattedDate(this.showingDate, { month: 'long' })
    },
  },
  async created() {
    this.showingDate = new Date()
    this.monthsJson = dateData.months
    await this.pageLoad()
  },
  computed: {
    ...mapGetters({
      campPrayerListing: 'prayerCenter/campPrayerListing',
      dailyPrayers: 'prayerCenter/dailyData',
      internationalPrayerListing: 'prayerCenter/internationalPrayerListing',
      monthPrayerRequest: 'prayerCenter/monthPrayerRequest',
      prefCulture: 'user/userPreferredCulture',
      statePrayerListing: 'prayerCenter/statePrayerListing',
    }),
    showingMonth() {
      let monthName = dateData.months.find((mi) => parseInt(mi.value) === this.showingDate.getMonth() + 1).text
      return `${monthName}`
    },
    options() {
      let filteredOptions = []
      if (this.currentFilter === 'all') {
        filteredOptions = filteredOptions.concat(this.getCampPrayerListingData())
        filteredOptions = filteredOptions.concat(this.getStatePrayerListingData())
        filteredOptions = filteredOptions.concat(this.getInternationalPrayerListingData())
      } else if (this.currentFilter === 'camp') {
        filteredOptions = filteredOptions.concat(this.getCampPrayerListingData())
      } else if (this.currentFilter === 'state') {
        filteredOptions = filteredOptions.concat(this.getStatePrayerListingData())
      } else if (this.currentFilter === 'headquarters') {
        filteredOptions = filteredOptions.concat(this.getInternationalPrayerListingData())
      }
      return filteredOptions
    },
  },
  components: {
    reportHeader: ReportHeader,
    FullCalendar,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/views/ReportCenter.scss';
@import '@/styles/bootstrap/b-button.scss';
</style>
